import React from 'react'
import classNames from 'classnames'

import Button from '@bit/azheng.joshua-tree.button';
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../../components/ImageMeta"

const AAAHC = ({ post, language }) => {
    if (!post || !post.hasThisSection) return <></>

    const imgBtnClasses = classNames(
        "image-btn-row",
        {
            "no-btn": !post.button.useButton
        }
    )
    const bgImage = {
        backgroundImage: `url('https://res.cloudinary.com/nuvolum/image/upload/v1618528830/${post.backgroundImage}')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom"
    }

    return (
        <div className="home-aaahc" style={post.backgroundImage ? bgImage : {}}>
            <div className="columns">
                <div className="column is-3"></div>
                <div className="column">
                    <h3 className="has-text-centered">{post.heading}</h3>
                    <MarkdownViewer markdown={post.blurb} />

                    <div className={imgBtnClasses}>
                        <ImageMeta
                            cloudName="nuvolum"
                            publicId={post.imageId}
                            responsive
                        />

                        {post.button && post.button.useButton &&
                            <Button
                                contained
                                href={post.button.href}
                                buttonText={post.button.buttonText}
                            />
                        }
                    </div>
                </div>
                <div className="column is-3"></div>
            </div>
        </div>
    )
}

export default AAAHC
